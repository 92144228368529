import { createBrowserRouter } from "react-router-dom";

import ErrorPage from "./components/pages/error";
import HomePage from "./components/pages/home";
import ShowRoom from "./components/pages/showroom";
import WebglView from "./components/templates/webglview";

// router 관리
export const router = createBrowserRouter([
  {
    path: "/",
    // element: <Layout />,
    // errorElement: <ErrorPage />,
    children: [{ index: true, element: <HomePage /> }],
  },
  {
    path: "/showroom",
    // element: <Layout />,
    // errorElement: <ErrorPage />,
    children: [{ index: true, element: <ShowRoom /> }],
  },
  {
    path: "/webglview",
    element:
      <WebglView
        loaderUrl={"webgl/Build_WebGL.loader.js"}
        dataUrl={"webgl/Build_WebGL.data"}
        frameworkUrl={"webgl/Build_WebGL.framework.js"}
        codeUrl={"webgl/Build_WebGL.wasm"}

      // loaderUrl={"https://timsolutionclusterstor.blob.core.windows.net/yokogawa-showroom/Build_WebGL.loader.js"}
      // dataUrl={"https://timsolutionclusterstor.blob.core.windows.net/yokogawa-showroom/Build_WebGL.data"}
      // frameworkUrl={"https://timsolutionclusterstor.blob.core.windows.net/yokogawa-showroom/Build_WebGL.framework.js"}
      // codeUrl={"https://timsolutionclusterstor.blob.core.windows.net/yokogawa-showroom/Build_WebGL.wasm"}
      />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/login",
  //   element: <LoginPage />,
  //   errorElement: <ErrorPage />,
  // },

  // {
  //   path: "/*",
  //   element: <Layout />,
  //   errorElement: <ErrorPage />,
  // },
]);
