import tw from 'tailwind-styled-components';
import styled from 'tailwind-styled-components';

export const SHomePage = tw.article``;

export const SBackGround = tw.div`w-full h-screen bg-[#004f9a] flex-center flex-col `;

export const SMainImage = tw.img`w-full h-full`;

export const SEntranceButton = tw.div`w-auto h-auto text-yellow-300 border py-3 px-6 rounded-xl my-5 border-yellow-300 cursor-pointer`;

export const SLanguageList = tw.div`w-1/5 h-auto flex flex-row justify-around`;

export const SLanguageButton = tw.div`w-auto h-auto text-yellow-300 border py-3 px-6 rounded-xl cursor-pointer border-yellow-300`;

export const SLanguageButtonDisable = tw.div`w-auto h-auto border py-3 px-6 rounded-xl disabled text-yellow-200 border-yellow-200`;

// export const SMainImage = tw.img`w-full h-full`;

export const SSelelctedObject = tw.div`absolute top-7 right-7 
 w-[640px] h-96 bg-opacity-0 bg-white animate-show-modal`;

export const SSelelctedObject1 = tw.div`absolute 
 w-[640px] h-96 bg-opacity-0 bg-white z-10`;

export const SSelelctedObjectBgEffect1 = tw.div`absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-2
 w-[300px] h-100 bg-opacity-0  z-0 flex flex-col justify-between`;
export const SSelelctedObjectBgEffectBarT = tw.div`w-full h-2 bg-white rounded-t-lg shadow-2xl`;
export const SSelelctedObjectBgEffectBarB = tw.div`w-full h-2 bg-white rounded-b-lg shadow-2xl`;

export const SSelelctedObjectBgEffect2 = tw.div`absolute
 top-0 left-1/2 transform -translate-x-1/2 
 w-[100px]  bg-opacity-100  flex flex-col justify-between z-90`;
export const SSelelctedObjectBgEffect3 = tw.div`absolute
 bottom-0 left-1/2 transform -translate-x-1/2 
 w-[100px]  bg-opacity-100  flex flex-col justify-between z-90`;
export const SSelelctedObjectBgEffectBarTS = tw.div`w-full h-3 bg-white rounded-b-lg z-90 shadow-2xl`;
export const SSelelctedObjectBgEffectBarBS = tw.div`w-full h-3 bg-white rounded-t-lg z-90 shadow-2xl`;

export const SDataShow1 = tw.div`w-full h-1/2 flex flex-row`;
export const SDataShow1Title = tw.div`font-semibold text-base ml-2 mt-2`;
export const SFlex = tw.div`flex flex-row w-full h-2/5`;
export const SDataShow2 = tw.div`w-1/3 h-5/6  mx-2 -mb-4`;
// bg-[#272c41] Rmx
// bg-[#1e1e35]aosenl

export const STitleArea = tw.div`w-full h-10 flex flex-row justify-between items-center px-5`;
export const STitlePosition = tw.div`font-semibold z-10 text-3xl mt-2`;
export const SCloseButton = tw.div`z-10`;

export const SSkipButton = tw.div`absolute top-1/2 right-10
rounded-full
 transform -translate-y-1/2 flex flex-row
 py-2 px-3 bg-[#034f9b] text-white cursor-pointer
 text-3xl
 bg-opacity-80 backdrop-blur-lg`;
export const SSkipButtonIcon = tw.div`bg-white px-2.5 rounded-full`;

export const SDataShow2Title = tw.div`font-semibold xl:text-sm sm:text-xs md:text-sm  `;
export const SDataShow2Title1 = tw.div`font-light xl:text-sm sm:text-xs md:text-sm  `;
export const SDataShow2Main = tw.div`font-light h-1/2 mt-3 flex justify-center items-center xl:text-4xl sm:text-sm md:text-base `;
export const SCirecleText = tw.div`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl`;

// CableFix 여기부터
export const SCableFixBackground = tw.div`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  
 w-1/2 h-[600px] `;

export const SCableFixTop = tw.div`w-full flex flex-row justify-between items-center px-3`;
export const SCableFixTitle = tw.div`text-xl font-bold`;

export const SBounceArea = tw.div`flex flex-row mt-10 `;
export const SBounceText = tw.span`text-yellow-300 text-6xl`;
