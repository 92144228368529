import { ResponsiveLine } from "@nivo/line"

import { SDataShow1Title } from "./ShowRoom.style"
// import { generateDrinkStats } from '@nivo/generators'

const GrowingLine = (props: any) => {

  return (
    <>
      <SDataShow1Title>Temp Flow</SDataShow1Title>
      <ResponsiveLine
        data={props?.data}
        margin={{ top: 20, right: 30, bottom: 70, left: 40 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 0,
          max: props?.data[0]?.data[props?.data[0]?.data?.length - 1]?.y >= 100
            ? 250
            : 80,
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        curve="natural"
        pointSize={0}
        lineWidth={4}

        colors={'yellow'}
        enablePoints={false}
        enableGridX={true}
        theme={{ textColor: '#fff', }}
        animate={false}
        // isInteractive={false}
        enableSlices={false}

        useMesh={true}
        tooltip={({ point }) => (
          <div style={{
            background: 'white',
            padding: '4px 5px',
            borderRadius: '10px'

          }}>
            <div style={{ color: 'gray' }}>
              <strong>Time : {point?.data?.xFormatted}</strong>
            </div>
            <div style={{ color: 'black' }}>
              <strong>Temp : {Math.round(Number(point?.data?.yFormatted))}℃</strong>
            </div>
          </div>
        )}
      />
    </>

  )
}
export default GrowingLine