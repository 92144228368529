import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import ReactPlayer from "react-player"

import { IconButton } from "../../molecules/buttons/IconButton"
import { SCableFixBackground, SCableFixTitle, SCableFixTop } from "./ShowRoom.style"

const CableFixMovie = (props: any) => {
  return (
    <SCableFixBackground>
      <div style={{
        width: '100%', height: '100%',
        background: 'transparent linear-gradient(180deg, #00B1FF99 0%, #00316C99 100%) 0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 25px #FFFFFF',
        border: '3px solid #FFFFFF',
        borderRadius: '24px',
      }}>
        <SCableFixTop>
          <SCableFixTitle>Cable Fix</SCableFixTitle>
          <div>
            <IconButton icon={faTimes} size="xl"
              onClick={() => { props.CableFixCloseButtonClick() }} />
          </div>
        </SCableFixTop>
        <div style={{
          width: '100%', height: '90%',
          padding: '10px'
        }}>
          <ReactPlayer
            url={'video/WireAnimRender03.mp4'}
            playing={true}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </SCableFixBackground >
  )
}
export default CableFixMovie
