import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { Circle } from "rc-progress"
import GaugeChart from 'react-gauge-chart'

import { IconButton } from "../../molecules/buttons/IconButton"
import GrowingLine from "./line"
import { SCirecleText, SCloseButton, SDataShow1, SDataShow2, SDataShow2Main, SDataShow2Title, SDataShow2Title1, SFlex, SSelelctedObject, SSelelctedObject1, SSelelctedObjectBgEffect1, SSelelctedObjectBgEffect2, SSelelctedObjectBgEffect3, SSelelctedObjectBgEffectBarB, SSelelctedObjectBgEffectBarBS, SSelelctedObjectBgEffectBarT, SSelelctedObjectBgEffectBarTS, STitleArea, STitlePosition } from "./ShowRoom.style"

const SelectedUI = (props: any) => {

  return (
    <SSelelctedObject>
      <SSelelctedObject1>
        <div style={{
          position: "absolute",
          width: '100%', height: '100%',
          background: 'transparent linear-gradient(180deg, #00B1FF99 0%, #00316C99 100%) 0% 0% no-repeat padding-box',
          boxShadow: '0px 0px 25px #FFFFFF',
          border: '3px solid #FFFFFF',
          borderRadius: '24px',
          zIndex: 0,
        }}>
          <STitleArea>
            <STitlePosition>{props.title}</STitlePosition>
            <SCloseButton>
              <IconButton icon={faTimes} size="xl"
                onClick={() => { props.hiddenSelectedObject() }} />
            </SCloseButton>
          </STitleArea>
          <SDataShow1>
            <div style={{
              width: '63%',
              height: '90%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              background: 'rgba(255,255,255,0.3) 0% 0% no-repeat padding-box',
              borderRadius: '12px',
              marginTop: '0.5rem',
              marginLeft: '1rem',
              marginRight: '1rem',
              color: 'white'
            }}>
              <div style={{ width: '100%' }}>
                <GrowingLine
                  data={props.chartData}
                />
              </div>
            </div>
            <div style={{
              width: '29%',
              height: '90%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              background: 'rgba(255,255,255,0.3) 0% 0% no-repeat padding-box',
              borderRadius: '12px',
              marginTop: '0.5rem',
              marginLeft: '0rem',
              marginRight: '1rem',
              color: 'white'
            }}>
              <div style={{
                width: '100%', padding: '15px', display: 'flex', flexDirection: 'column',
                height: '100%',
                // border: '1px solid rgba(255,255,255)'
              }}>
                <div
                  style={{ width: "100%", textAlign: 'left', marginTop: '-10px', fontFamily: 'Inter-SemiBold' }}>
                  Current Status
                </div>
                <div style={{ width: "100%", height: '100%', marginTop: '20px', fontSize: '30px' }}>
                  <GaugeChart
                    id="gauge-chart1"
                    animate={false}
                    percent={props.temperature < 33
                      ? 17 / 100
                      : props.temperature < 66
                        ? 50 / 100
                        : 83 / 100}
                    marginInPercent={0}
                    style={{ width: "100%", height: "100%", fontSize: '30px' }}

                    textColor={props.temperature < 33
                      ? '#0f0'
                      : props.temperature < 66
                        ? '#ff0'
                        : '#f00'}
                    fontSize="22px"

                    formatTextValue={(value: any) => {
                      if (value < 33) {
                        return 'Safe'
                      }
                      if (value < 66) {
                        return 'Warning'
                      }
                      if (value < 99) {
                        return 'Danger'
                      }
                      return ''
                    }}
                  />
                </div>
              </div>
            </div>
          </SDataShow1>
          <SFlex>
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'row', margin: '0.5rem' }}>
              <SDataShow2>
                <div style={{
                  width: '100%', height: '100%',
                  padding: '0.5rem',
                  background: 'rgba(255,255,255,0.3) 0% 0% no-repeat padding-box',
                  borderRadius: '12px',
                }}>
                  <SDataShow2Title>Daily</SDataShow2Title>
                  <SDataShow2Title1>Maximum Temp</SDataShow2Title1>
                  <SDataShow2Main>43℃</SDataShow2Main>
                </div>

              </SDataShow2>
              <SDataShow2>
                <div style={{
                  width: '100%', height: '100%',
                  padding: '0.5rem',
                  background: 'rgba(255,255,255,0.3) 0% 0% no-repeat padding-box',
                  borderRadius: '12px',
                }}>
                  <SDataShow2Title>Daily</SDataShow2Title>
                  <SDataShow2Title1>Minimum Temp</SDataShow2Title1>
                  <SDataShow2Main>7℃</SDataShow2Main>
                </div>
              </SDataShow2>
              <SDataShow2>
                <div style={{
                  width: '100%', height: '100%',
                  padding: '0.5rem',
                  background: 'rgba(255,255,255,0.3) 0% 0% no-repeat padding-box',
                  borderRadius: '12px',
                }}>
                  <SDataShow2Title>Weekly</SDataShow2Title>
                  <SDataShow2Title1>Average Temp</SDataShow2Title1>
                  <SDataShow2Main>28℃</SDataShow2Main>
                </div>
              </SDataShow2>
            </div>
          </SFlex>
        </div>
        {/* 위아래 모양잡기 */}
        <SSelelctedObjectBgEffect2>
          <SSelelctedObjectBgEffectBarTS />
        </SSelelctedObjectBgEffect2>
        <SSelelctedObjectBgEffect3>
          <SSelelctedObjectBgEffectBarBS />
        </SSelelctedObjectBgEffect3>
      </SSelelctedObject1>
      <SSelelctedObjectBgEffect1>
        <SSelelctedObjectBgEffectBarT />
        <SSelelctedObjectBgEffectBarB />
      </SSelelctedObjectBgEffect1>
    </SSelelctedObject>
  )
}
export default SelectedUI
