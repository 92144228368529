import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";

export interface IIcon {
  color?: string;
  icon: IconProp;
  size?: SizeProp;
  style?: CSSProperties;
}

export const Icon = ({ color, icon, size, style }: IIcon) => {
  return (
    <FontAwesomeIcon
      icon={icon}
      color={color || "white"}
      size={size || "lg"}
      style={style}
    />
  );
};
