import tw from "tailwind-styled-components";


export const MainBox = tw.div`absolute w-full h-full`;
export const LoadingBox = tw.div`w-full h-full z-90 flex-center`;


export const LoadingBoxGIF = tw.div`w-full h-full z-80 flex-col flex-center bg-[#121212]`;


export const Loading = tw.img` h-full z-90 flex-center`;

export const LoadingPercent = tw.span`absolute top-1/2 left-1/2  transform -translate-x-1/2 -translate-y-1/2 text-white z-90 text-xl`;

