import { MouseEventHandler } from "react";

import { Icon, IIcon } from "../../atomics/Icon";

interface IIconButton extends IIcon {
  onClick: MouseEventHandler<HTMLButtonElement>;
}
export const IconButton = ({ onClick, color, icon, size }: IIconButton) => {
  return (
    <button className="relative p-2 rounded-sm flex-center" onClick={onClick}>
      <Icon icon={icon} color={color} size={size} />
    </button>
  );
};
