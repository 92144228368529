import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";

import { reactQueryClient } from "./client/client";
import { router } from "./router";
function App() {
  return (
    <QueryClientProvider client={reactQueryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
