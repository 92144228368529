import { useState } from "react";
import Iframe from "react-iframe";

import CableFixMovie from "./cableFix";
import SelectedUI from "./selectedui";
import useInterval from "./useInterval";


const ShowRoom = () => {
  const nowTimeHHMMSS = () => {
    const now = Date.now(); // 현재 시간을 밀리초 단위로 반환
    const date = new Date(now); // 밀리초 단위 시간 값을 Date 객체로 변환

    const hours = date.getHours(); // 시간
    const minutes = date.getMinutes(); // 분
    const seconds = date.getSeconds(); // 초

    return `${hours}:${minutes}:${seconds}`
  }

  const [chartTankData, setChartTankData] = useState([
    {
      "id": "tankTemperature",
      "data": [
        {
          "x": nowTimeHHMMSS(),
          "y": 0
        },
      ]
    }
  ])
  const [chartPipeData, setChartPipeData] = useState([
    {
      "id": "tankTemperature",
      "data": [
        {
          "x": nowTimeHHMMSS(),
          "y": 0
        },
      ]
    }
  ])

  // 랜덤 값 함수
  function getRandomInt(min: any, max: any) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const [tankTemperature, setTankTemperature] = useState(0);
  const [pipeTemperature, setPipeTemperature] = useState(0);
  const [type, setType] = useState("normal");

  // 2초마다 tank 차트 갱신
  useInterval(() => {
    let newTankTemperature = 0;

    switch (type) {
      case "normal":
        newTankTemperature = getRandomInt(20, 30);
        break;
      case "HeatLine":
        newTankTemperature = getRandomInt(50, 60);
        break;
      case "FireOccur":
        newTankTemperature = getRandomInt(200, 220);
        break;
      default:
        newTankTemperature = tankTemperature;
        break;
    }

    const tmpData: any = chartTankData;
    tmpData[0].data.push({
      "x": nowTimeHHMMSS(),
      "y": newTankTemperature
    });
    if (tmpData[0].data.length > 6) {
      tmpData[0].data.shift()
    }
    setChartTankData(tmpData)
    setTankTemperature(newTankTemperature);
  }, 2000);

  // 2초마다 piping 차트 갱신
  useInterval(() => {
    let newPipeTemperature = 0;

    switch (type) {
      case "normal":
        newPipeTemperature = getRandomInt(20, 30);
        break;
      case "HeatLine":
        newPipeTemperature = getRandomInt(50, 60);
        break;
      case "FireOccur":
        newPipeTemperature = getRandomInt(200, 220);
        break;
      default:
        newPipeTemperature = pipeTemperature;
        break;
    }

    const tmpData: any = chartPipeData;
    tmpData[0].data.push({
      "x": nowTimeHHMMSS(),
      "y": newPipeTemperature
    });
    if (tmpData[0].data.length > 6) {
      tmpData[0].data.shift()
    }
    setChartPipeData(tmpData)
    setPipeTemperature(newPipeTemperature);
  }, 2000);

  // 팝업 상태 State
  const [visibleSelectedTank, setVisibleSelectedTank] = useState(false)
  const [visibleSelectedPipe, setVisibleSelectedPipe] = useState(false)
  const [visibleCableFixMovie, setVisibleCableFixMovie] = useState(false)

  // Iframe 메세지 수신
  window.onmessage = (event: MessageEvent) => {
    if (event.data.type === 'TankEvent') {
      if (event.data.value === 'true') {
        setVisibleSelectedTank(true)
        setVisibleSelectedPipe(false)
      } else {
        setVisibleSelectedTank(false)
        setVisibleSelectedPipe(false)
      }
    }
    if (event.data.type === 'PipeEvent') {
      if (event.data.value === 'true') {
        setVisibleSelectedPipe(true)
        setVisibleSelectedTank(false)
      } else {
        setVisibleSelectedPipe(false)
        setVisibleSelectedTank(false)
      }
    }

    // 차트에 소폭 온도상승
    if (event.data.type === 'HeatLineEvent') {
      if (event.data.value === 'true') {
        setType("HeatLine")
      } else {
        setType("normal")
      }
    }

    // 차트에 대폭 온도상승
    if (event.data.type === 'FireOccurEvent') {
      if (event.data.value === 'true') {
        setType("FireOccur")
      } else {
        setType("normal")
      }
    }

    if (event.data.type === 'CableFix') {
      if (event.data.value === 'true') {
        setVisibleCableFixMovie(true)
      } else {
        setVisibleCableFixMovie(false)
      }
    }
  };

  // 창닫기
  const hiddenSelectedObject = () => {
    setVisibleSelectedTank(false)
    setVisibleSelectedPipe(false)
  }

  // CableFix 동영상 창 닫기
  const CableFixCloseButtonClick = () => {
    setVisibleCableFixMovie(false)
  }

  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: 'black' }}>
      <Iframe
        id={"iframe-111"}
        width="100%"
        height="100%"
        url={`/webglview`}
        frameBorder={0}
      />
      {visibleSelectedTank &&
        <SelectedUI
          title={'TANK'}
          chartData={chartTankData}
          temperature={tankTemperature}
          hiddenSelectedObject={hiddenSelectedObject}
        />
      }
      {visibleSelectedPipe &&
        <SelectedUI
          title={'PIPING'}
          chartData={chartPipeData}
          temperature={pipeTemperature}
          hiddenSelectedObject={hiddenSelectedObject}
        />
      }
      {visibleCableFixMovie &&
        <CableFixMovie
          CableFixCloseButtonClick={CableFixCloseButtonClick}
        />
      }
    </div >
  );
};

export default ShowRoom;
