import tw from 'tailwind-styled-components';

export const SHomePage = tw.article``;

export const SBackGround = tw.div`w-full h-screen bg-[#004f9a] flex-center flex-col `;

export const SMainImage = tw.img`w-full h-full`;

export const SEntranceButton = tw.div`w-auto h-auto text-yellow-300 border-2 font-bold py-3 px-6 rounded-xl my-5 border-yellow-300 cursor-pointer hover:bg-yellow-300 hover:text-gray-500`;

export const SLanguageList = tw.div`w-1/5 h-auto flex flex-row justify-around`;

export const SLanguageButton = tw.div`w-auto h-auto text-yellow-300 border-2 font-bold py-3 px-6 rounded-xl cursor-pointer border-yellow-300`;

export const SLanguageButtonDisable = tw.div` 
w-auto h-auto border-2 font-bold py-3 px-6
rounded-xl disabled text-gray-400 border-gray-400`;

// export const SMainImage = tw.img`w-full h-full`;
